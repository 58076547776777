<!-- Navigation List / Sidebar -->
<div class="navigation">
  <mat-list *ngIf="userIsAuthenticated">
    <mat-list-item [routerLink]="['/field-tags']">
      Erfahrungsgebiete
    </mat-list-item>
    <mat-divider></mat-divider>

    <mat-list-item [routerLink]="['/question-list']"> Fragen </mat-list-item>
    <mat-divider></mat-divider>

    <mat-list-item [routerLink]="['/question-create']">
      Frage erstellen
    </mat-list-item>
    <mat-divider></mat-divider>

    <mat-list-item [routerLink]="['/sessions']"> Sessions </mat-list-item>
    <mat-divider></mat-divider>

    <mat-list-item [routerLink]="['/users']">Benutzer</mat-list-item>
    <mat-divider></mat-divider>

    <mat-list-item [routerLink]="['/settlements']">Abrechnung</mat-list-item>
    <mat-divider></mat-divider>

    <mat-list-item (click)="logOut()">Logout</mat-list-item>
  </mat-list>
</div>
