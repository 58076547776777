import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { Session } from 'src/app/models/session.model';

@Component({
  selector: 'app-question-edit',
  templateUrl: './session-edit.component.html',
  styleUrls: ['./session-edit.component.scss'],
})
export class SessionEditComponent implements OnInit, OnDestroy {
  session: Session;
  private unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(private route: ActivatedRoute) {}

  // ---------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // ---------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.session = this.route.snapshot.data.session;
  }

  /**
   * On delete
   */
  ngOnDestroy(): void {
    this.unsubscribeAll.complete();
  }

  // ---------------------------------------------------------------------------------------------
  // @ Public methods
  // ---------------------------------------------------------------------------------------------

  /**
   * Formats the diy costs based on the provided parameters.
   * @param totalCostCent The total cost in cents
   * @returns A string representation of the total costs in euro
   */
  formatDIYCost(totalCostCent: number) {
    const totalCost = totalCostCent / 100;
    return `${totalCost.toFixed(2)}€`.replace('.', ',');
  }

  /**
   * Formats the craftsman's income based on the provided parameters.
   * @param totalCost The total cost in cents
   * @param provision The vSupport provision
   * @returns A string representation of the craftsman's income in euro
   */
  calculateCraftsmanIncome(totalCost: number, provision: number) {
    const totalIncome = (totalCost * (1 - provision)) / 100;
    return `${totalIncome.toFixed(2)}€`.replace('.', ',');
  }
}
