import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  restServerUrl;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor() { 
    this.restServerUrl = environment.apiServer;
  }

  ngOnInit() { }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
