<div class="content-wrap">
  <h1>Erfahrungsgebiet hinzufügen</h1>
  <!-- Search -->
  <div class="add-field-tag-form">
    <mat-form-field
      class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded"
    >
      <mat-icon
        class="icon-size-5"
        matPrefix
        [svgIcon]="'heroicons_solid:search'"
      ></mat-icon>
      <input
        matInput
        [formControl]="searchInputControl"
        [autocomplete]="'off'"
        [placeholder]="'Erfahrungsgebiet hinzufügen'"
      />
    </mat-form-field>
    <button mat-fab color="primary" (click)="addFieldTag()">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <!-- All Field tag list -->
  <h2>Admin Erfahrungsgebiete</h2>
  <mat-grid-list cols="2" rowHeight="2rem">
    <ng-container *ngFor="let fieldTag of filteredUserFieldTags">
      <mat-grid-tile colspan="1">
        <div class="field-tag-tile">
          <div class="field-tag-text">{{ fieldTag }}</div>
          <mat-icon class="delete-icon" (click)="removeFieldTag(fieldTag)"
            >delete</mat-icon
          >
        </div>
      </mat-grid-tile>
    </ng-container>
  </mat-grid-list>

  <h2>Alle Erfahrungsgebiete</h2>
  <!-- All Field tag list -->
  <mat-grid-list cols="2" rowHeight="2rem">
    <ng-container *ngFor="let fieldTag of filteredFieldTags">
      <mat-grid-tile colspan="1">
        <div class="field-tag-tile">
          {{ fieldTag }}
        </div>
      </mat-grid-tile>
    </ng-container>
  </mat-grid-list>

  <!-- Question List -->
  <!-- <mat-grid-list cols="12">
    <ng-container
      *ngFor="let question of filteredQuestions"
      class="question-list__container"
    >
      <mat-grid-tile colspan="6">
        <div class="question-list__question">
          {{ formatQuestion(question.question) }}
        </div>
      </mat-grid-tile>
      <mat-grid-tile colspan="3">
        {{ question.craft }}
      </mat-grid-tile>
      <mat-grid-tile colspan="3">
        <div class="question-list__actions">
          <button
            mat-button
            color="primary"
            [routerLink]="['/question-edit', question._id]"
          >
            Bearbeiten
          </button>
          <button mat-button color="warn" (click)="delete(question._id)">
            Löschen
          </button>
        </div>
      </mat-grid-tile>
    </ng-container>
  </mat-grid-list> -->
</div>
