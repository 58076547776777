import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/providers/notification.service';
import { map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { UserService } from 'src/app/providers/user.service';
import { Language, User, UserRole } from 'src/app/models/user.model';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-user-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class UserCreateComponent implements OnInit, OnDestroy {
  fieldTags: string[];
  languages: Language[];
  roles: UserRole;
  userForm: FormGroup;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  userFieldTags: string[] = [];
  filteredFieldTags: Observable<string[]>;
  private unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private notificationService: NotificationService
  ) {}

  // ---------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // ---------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    // get resolved tags
    this.fieldTags = this.route.snapshot.data['fieldTags'];

    // dummy languages
    this.languages = [
      { name: 'Deutsch', abbreviation: 'de' },
      { name: 'Englisch', abbreviation: 'en' },
      { name: 'Spanisch', abbreviation: 'es' },
      { name: 'Französisch', abbreviation: 'fr' },
    ];

    // initialize user form
    this.userForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['temporary', [Validators.required]],
      role: ['diy', [Validators.required]],
      firstname: ['', []],
      lastname: ['', []],
      streetNr: ['', []],
      plz: ['', []],
      city: ['', []],
      languages: ['', []],
      description: ['', []],
      fieldTags: ['', []],
    });

    // change validators based on role
    this.userForm.controls.role.valueChanges.subscribe({
      next: (role: UserRole) => {
        this.setCraftsmanValidation(role);
      },
    });

    // subscribe to fieldTag field and listen for changes
    this.filteredFieldTags = this.userForm.controls.fieldTags.valueChanges.pipe(
      takeUntil(this.unsubscribeAll),
      startWith(null),
      map((tag: string | null) =>
        tag ? this.filterFieldTags(tag) : this.fieldTags.slice()
      )
    );
  }

  /**
   * On delete
   */
  ngOnDestroy(): void {
    this.unsubscribeAll.complete();
  }

  // ---------------------------------------------------------------------------------------------
  // @ Getters and Setters
  // ---------------------------------------------------------------------------------------------

  /**
   * Check if new user is supposed to be a craftsman
   * @returns true if the user is a craftsman
   */
  public get isCraftsman(): boolean {
    return this.userForm.value.role === 'craftsman';
  }

  /**
   * Check if new user is supposed to be an admin
   * @returns true if the user is an admin
   */
  public get isAdmin(): boolean {
    return this.userForm.value.role === 'admin';
  }

  // ---------------------------------------------------------------------------------------------
  // @ Private methods
  // ---------------------------------------------------------------------------------------------

  /**
   * Filter out field tags based on the current input
   * @param value fieldTag as `string`
   * @returns `String array` of the filterec field tags
   */
  private filterFieldTags = (value: string): string[] => {
    const filterValue = value.toLowerCase();
    return this.fieldTags.filter((tag) =>
      tag.toLowerCase().includes(filterValue)
    );
  };

  /**
   * Set or clear craftsman validation if the new user is supposed to be a craftsman
   * @param role Role of the user as `UserRole`
   */
  private setCraftsmanValidation = (role: UserRole) => {
    const formControls = this.userForm.controls;
    const firstname = formControls.firstname;
    const lastname = formControls.lastname;

    if (role === 'admin' || role === 'diy') {
      firstname.clearValidators();
      lastname.clearValidators();
    } else {
      firstname.addValidators([Validators.required]);
      firstname.markAsPristine();
      firstname.markAsUntouched();
      lastname.addValidators([Validators.required]);
      lastname.markAsPristine();
      lastname.markAsUntouched();
    }
    firstname.updateValueAndValidity();
    lastname.updateValueAndValidity();
  };

  // ---------------------------------------------------------------------------------------------
  // @ Public methods
  // ---------------------------------------------------------------------------------------------

  /**
   * Add a field tag to the users field tags if the field tag has more than 3
   * characters.
   *
   * Displays an error notification if the field tag to acc has less than 3
   * characters.
   * @param event
   */
  addFieldTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value.length < 3) {
      this.notificationService.setNotification = {
        type: 'error',
        message:
          'Ein Erfahsungsbereich muss aus mindestens 3 Zeichen bestehen.',
      };
      return;
    }
    this.userFieldTags.push(value);
    event.chipInput!.clear();
  }

  /**
   * Removes a field tag from the users field tags
   * @param tag
   */
  removeFieldTag(tag: string): void {
    const index = this.userFieldTags.indexOf(tag);

    if (index >= 0) {
      this.userFieldTags.splice(index, 1);
    }
  }

  /**
   * Add a field tag based on the already existing and filtered field tags.
   * @param event
   */
  selectFieldTag(event: MatAutocompleteSelectedEvent): void {
    this.userFieldTags.push(event.option.viewValue);
    this.userForm.patchValue({
      fieldTags: '',
    });
  }

  /**
   * Submits the new user
   *
   * Fails if the form is invalid.
   * Redirects to user list if successful.
   */
  onSubmit() {
    if (this.userForm.invalid) {
      return;
    }
    const formValues = this.userForm.value as User;
    delete formValues.fieldTags;
    if (formValues.role === 'craftsman') {
      formValues.fieldTags = this.userFieldTags;
    }

    this.userService.createUser(formValues).subscribe({
      next: () => {
        this.notificationService.setNotification = {
          type: 'success',
          message: 'Der Benutzer wurde erfolgreich angelegt',
        };
        this.router.navigate(['/users']);
      },
      error: () => {
        this.notificationService.setNotification = {
          type: 'error',
          message: 'Es ist ein Fehler aufgetreten',
        };
      },
    });
  }
}
