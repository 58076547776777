import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { AuthService } from '../auth/auth.service';;
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  userIsAuthenticated: boolean = false;
  private authListenerSubs: Subscription;

  constructor(
    public router: Router,
    private authService : AuthService,
    public authGuard:AuthGuard,
  ) { }

  ngOnInit(): void {
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authService.autoAuthUser();
    this.authListenerSubs = this.authService.getAuthStatusListener()
      .subscribe(isAuthenticated => {
        if (isAuthenticated) {
          this.userIsAuthenticated = isAuthenticated;
        }
    });
  }

  logOut(){
    this.authService.logout();
    this.userIsAuthenticated = false;
  }
}
