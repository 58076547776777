import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Settlement } from '../models/payment.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private restServerUrl: string;

  constructor(private http: HttpClient) {
    this.restServerUrl = environment.apiServer;
  }

  public getSettlements(year: string, month: string): Observable<Settlement[]> {
    return this.http.post<Settlement[]>(
      this.restServerUrl + '/api/p/craftsmanSettlement',
      {
        year,
        month,
      }
    );
  }
}
