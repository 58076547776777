import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Craft, User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private restServerUrl;
  private users: BehaviorSubject<User[]> = new BehaviorSubject([]);
  private crafts: BehaviorSubject<Craft[]> = new BehaviorSubject([]);
  private fieldTags: BehaviorSubject<string[]> = new BehaviorSubject([]);

  constructor(private http: HttpClient) {
    this.restServerUrl = environment.apiServer;
  }

  // ---------------------------------------------------------------------------------------------
  // @ Getters
  // ---------------------------------------------------------------------------------------------

  /**
   * Getter for users
   */
  public get users$(): Observable<User[]> {
    return this.users.asObservable();
  }

  /**
   * Getter for crafts
   */
  public get crafts$(): Observable<Craft[]> {
    return this.crafts.asObservable();
  }

  // ---------------------------------------------------------------------------------------------
  // @ Public methods
  // ---------------------------------------------------------------------------------------------

  /**
   * Gets a list of crafts with their slug
   * @returns Observable of all crafts
   */
  public getCrafts(): Observable<Craft[]> {
    return this.http.get<Craft[]>(this.restServerUrl + '/api/crafts').pipe(
      tap((crafts) => {
        this.crafts.next(crafts);
      })
    );
  }

  /**
   * Gets the provided taglist
   * @returns Observable of tags as a string array
   */
  public getFieldTags(): Observable<string[]> {
    return this.http
      .get<string[]>(this.restServerUrl + '/api/u/fieldTags')
      .pipe(tap((fieldTags) => this.fieldTags.next(fieldTags)));
  }

  /**
   * Gets a list of all users
   * @returns Observable of all users
   */
  public getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.restServerUrl + '/api/u/a/list').pipe(
      tap((users) => {
        this.users.next(users);
      })
    );
  }

  /**
   * Gets a single user based on it's id.
   * @param id userId as string
   * @returns Observable of the requested user
   */
  public getUser(id: string): Observable<User> {
    return this.http.get<User>(this.restServerUrl + '/api/u/a/' + id);
  }

  /**
   * Gets the currently authenticated user.
   * @returns Observable of the authenticated user
   */
  public getOwnUser(): Observable<User> {
    return this.http.get<User>(this.restServerUrl + '/api/u/ownUser');
  }

  /**
   * Create a new user
   * @param user the new user
   * @returns Observable of the created user
   */
  public createUser(user: User): Observable<User> {
    return this.http.post<User>(this.restServerUrl + '/api/u/a/create', user);
  }

  /**
   * Update a single user based on it's id
   * @param id userId as string
   * @param user updated user
   * @returns Observable of the to updating user
   */
  public updateUser(id: string, user: User): Observable<User> {
    return this.http.put<User>(this.restServerUrl + '/api/u/a/' + id, user);
  };


  /**
   * Sends a Mail to a craftsman for his validation
   * @param id userId as string
   * @returns Observable of the to updating user
   */
  public sendValidationMail(id: string): Observable<User> {
    return this.http.get<any>(this.restServerUrl + '/api/u/a/sendValidationMail/' + id);
  };

  /**
   * Update logged in user
   * @param user updated user
   * @returns Observable of the to updating user
   */
  public updateOwnUser(user: User): Observable<User> {
    return this.http.put<User>(
      this.restServerUrl + '/api/u/updateOwnUser',
      user
    );
  }

  /**
   * Delete a single user based on it's id
   * @param id userId as string
   * @returns Observable of the deleted user
   */
  public deleteUser(id: string): Observable<User> {
    return this.http
      .delete<User>(this.restServerUrl + '/api/u/a/' + id)
      .pipe(tap(() => this.getUsers().subscribe()));
  }
}
