<div class="container-fluid">
    <div class="login row">
      <div class="col-md-12">
        <div class="button-group">
          <div class="login_wrap">
            <form (submit)="login(loginForm)" #loginForm="ngForm">
                <mat-form-field>
                  <input matInput name="email" ngModel type="email" placeholder="E-Mail" #emailInput="ngModel" required>
                  <mat-error *ngIf="emailInput.invalid">Please enter a valid email.</mat-error> 
                </mat-form-field>
                <mat-form-field>
                  <input type="password" name="password" ngModel matInput placeholder="Kennwort" #passwordInput="ngModel" required>
                  <mat-error *ngIf="passwordInput.invalid">Please enter a valid password.</mat-error> 
                </mat-form-field>
                <div class="instructionVideo_wrap">
                  <button mat-button color="accent" type="submit" class="size-big" aria-label="LOG IN">Einloggen</button>
                </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  </div>