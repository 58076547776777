<div class="content-wrap">
  <h1>Liste aller Sessions</h1>

  <mat-card>
    <mat-card-content>
      <mat-form-field>
        <mat-label>Suchfilter</mat-label>
        <input
          type="text"
          placeholder="Filter nach Session ID, Vornamen oder Nachnamen eines DIY Benutzers oder Handwerkers"
          aria-label="Session ID"
          matInput
          [formControl]="searchInput"
        />
      </mat-form-field>
    </mat-card-content>
  </mat-card>

  <!-- Session table -->
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    class="mat-elevation-z8 question-table"
  >
    <!-- Session ID Column -->
    <ng-container matColumnDef="displayId">
      <th mat-header-cell *matHeaderCellDef>Session ID</th>
      <td mat-cell *matCellDef="let session">
        {{ session.displayId }}
      </td>
    </ng-container>

    <!-- Craftsman Column -->
    <ng-container matColumnDef="_craftsman">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Nach Handwerker sortieren"
      >
        Handwerker
      </th>
      <td mat-cell *matCellDef="let session">
        {{ session._craftsman }}
      </td>
    </ng-container>

    <!-- DIY Column -->
    <ng-container matColumnDef="_diy">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Nach DIY sortieren"
      >
        DIY
      </th>
      <td mat-cell *matCellDef="let session">
        {{ session._diy }}
      </td>
    </ng-container>

    <!-- Start Date Column -->
    <ng-container matColumnDef="start">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Nach Start Zeitpunkt sortieren"
      >
        Start
      </th>
      <td mat-cell *matCellDef="let session">
        {{ session.start | date : "dd.MM.yyyy - HH:mm:ss" }}
      </td>
    </ng-container>

    <!-- Duration Column -->
    <ng-container matColumnDef="duration">
      <th mat-header-cell *matHeaderCellDef>Dauer</th>
      <td mat-cell *matCellDef="let session">
        {{ formatDuration(session.duration) }}
      </td>
    </ng-container>

    <!-- Calculated Duration Column -->
    <ng-container matColumnDef="calcDuration">
      <th mat-header-cell *matHeaderCellDef>Berechnete Dauer</th>
      <td mat-cell *matCellDef="let session">
        {{ formatDuration(session.chargedDuration) }}
      </td>
    </ng-container>

    <!-- Cost per Minute Column -->
    <ng-container matColumnDef="costPerMinute">
      <th mat-header-cell *matHeaderCellDef>Kosten pro Minute in Cent</th>
      <td mat-cell *matCellDef="let session">
        {{ session.costCentPerMinute }}
      </td>
    </ng-container>

    <!-- Total cost for diy Column -->
    <ng-container matColumnDef="totalCost">
      <th mat-header-cell *matHeaderCellDef>DIY Gesamtkosten in Euro</th>
      <td mat-cell *matCellDef="let session">
        {{ formatDIYCost(session.totalCostCent) }}
      </td>
    </ng-container>

    <!-- Total income for craftsman Column -->
    <ng-container matColumnDef="totalIncome">
      <th mat-header-cell *matHeaderCellDef>
        Handwerker Gesamteinnahmen in Euro
      </th>
      <td mat-cell *matCellDef="let session">
        {{
          calculateCraftsmanIncome(
            session.totalCostCent,
            session.vsupportProvision
          )
        }}
      </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let session">
        <button
          mat-button
          color="primary"
          [routerLink]="['/sessions', session._id]"
        >
          Bearbeiten
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
