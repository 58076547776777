import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Session } from '../models/session.model';
import { SessionService } from './session.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SessionsResolver implements Resolve<Session[]> {
  constructor(private sessionService: SessionService) {}

  /**
   * Resolver
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Session[] | Observable<Session[]> | Promise<Session[]> {
    return this.sessionService.getSessions();
  }
}

@Injectable({ providedIn: 'root' })
export class SessionResolver implements Resolve<Session> {
  constructor(private sessionService: SessionService) {}

  /**
   * Resolver
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Session | Observable<Session> | Promise<Session> {
    const id = route.paramMap.get('id');
    return this.sessionService.getSession(id);
  }
}
