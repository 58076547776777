import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil, tap } from 'rxjs';
import { NotificationService } from 'src/app/providers/notification.service';
import { User } from '../models/user.model';
import { UserService } from '../providers/user.service';

@Component({
  selector: 'app-field-tags',
  templateUrl: './field-tags.component.html',
  styleUrls: ['./field-tags.component.scss'],
})
export class FieldTagsComponent implements OnInit, OnDestroy {
  userId: string;
  userFieldTags: string[];
  filteredUserFieldTags: string[];
  fieldTags: string[];
  filteredFieldTags: string[];
  searchInputControl: FormControl = new FormControl('');
  private unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private notificationService: NotificationService
  ) {}

  // ---------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // ---------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    const ownUser = this.route.snapshot.data.user as User;
    this.userId = ownUser._id;
    this.userFieldTags = ownUser.fieldTags;

    const fieldTags = this.route.snapshot.data.fieldTags as string[];
    this.fieldTags = fieldTags;
    this.filterFieldTags('');

    this.searchInputControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribeAll),
        tap((query) => this.filterFieldTags(query))
      )
      .subscribe();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.unsubscribeAll.complete();
  }

  // ---------------------------------------------------------------------------------------------
  // @ Private methods
  // ---------------------------------------------------------------------------------------------

  /**
   * Filter the field tags based on the selected craft
   */
  private filterFieldTags(filter: string) {
    this.filteredFieldTags = this.fieldTags
      .filter((fieldTag) =>
        fieldTag.toLowerCase().includes(filter.toLowerCase())
      )
      .sort();

    this.filteredUserFieldTags = this.userFieldTags
      .filter((fieldTag) =>
        fieldTag.toLowerCase().includes(filter.toLowerCase())
      )
      .sort();
  }

  private updateUser(message: string) {
    // update user
    this.userService
      .updateOwnUser({ fieldTags: this.userFieldTags })
      .subscribe({
        next: () => {
          this.notificationService.setNotification = {
            type: 'success',
            message,
          };
          this.filterFieldTags('');
          this.searchInputControl.setValue('');
        },
        error: () =>
          (this.notificationService.setNotification = {
            type: 'error',
            message: 'Ein Fehler ist aufgetreten',
          }),
      });
  }

  // ---------------------------------------------------------------------------------------------
  // @ Public methods
  // ---------------------------------------------------------------------------------------------
  addFieldTag() {
    const fieldTag = (this.searchInputControl.value as string).trim();

    if (fieldTag.length < 3) {
      this.notificationService.setNotification = {
        type: 'error',
        message:
          'Ein Erfahsungsbereich muss aus mindestens 3 Zeichen bestehen.',
      };
      return;
    }

    if (this.userFieldTags.includes(fieldTag)) {
      this.notificationService.setNotification = {
        type: 'error',
        message: 'Das angegebene Erfahrungsgebiet ist bereits vorhanden',
      };
      return;
    }

    this.fieldTags.push(fieldTag);
    this.userFieldTags.push(fieldTag);

    this.updateUser('Neues Erfahrungsgebiet hinzugefügt');
  }

  removeFieldTag(deletedFieldTag: string) {
    this.userFieldTags = this.userFieldTags.filter(
      (fieldTag) => fieldTag !== deletedFieldTag
    );

    this.updateUser('Erfahrungsgebiet erfolgreich gelöscht');
  }
}
