import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Question, Craft } from 'src/app/models/question.model';
import { User, UserCraft, UserRole } from 'src/app/models/user.model';
import { NotificationService } from 'src/app/providers/notification.service';
import { UserService } from 'src/app/providers/user.service';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class UserListComponent implements OnInit, OnDestroy {
  users: any;
  crafts: any;
  private unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private userService: UserService
  ) {}

  // ---------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // ---------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    // Resolved users and then listening for changes
    this.users = this.route.snapshot.data.users;
    this.userService.users$.pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (users) => (this.users = users),
    });

    // Resolved crafts
    this.crafts = this.route.snapshot.data.crafts;
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.unsubscribeAll.complete();
  }

  // ---------------------------------------------------------------------------------------------
  // @ Private methods
  // ---------------------------------------------------------------------------------------------

  // ---------------------------------------------------------------------------------------------
  // @ Public methods
  // ---------------------------------------------------------------------------------------------

  /**
   * Formats a question and replaces all characters after 30 characters with `...`
   * @param question
   * @returns
   */
  formatQuestion(question: string): string {
    if (question.length <= 100) return question;

    // trim if too long
    return question.substring(0, 100) + '...';
  }

  /**
   * Formats the craftSlug to the craft name
   * @param craftSlug
   * @returns
   */
  formatCraft(craftSlug: string[]): string {
    console.log(craftSlug)
    console.log(this.crafts)
    return (
      this.crafts.find((craft) => craftSlug[0] === craft.name)?.name ??
      'Kein Handwerker'
    );
  }

  /**
   * Formats the role in a better readable wao
   * @param role
   * @returns Formatted role as `string`
   */
  formatRole(role: UserRole): string {
    if (role === 'admin') return 'Administrator';
    else if (role === 'craftsman') return 'Handwerker';
    else return 'Anwender';
  }

  /**
   * Delete a question based on it's id
   * @param id the selected question id
   */
  delete(id: string) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '250px',
      data: { message: 'Möchten Sie diesen Benutzer wirklich löschen?' },
    });

    // if confirmed, delete the question and send a notification
    dialogRef.afterClosed().subscribe({
      next: (confirmed: boolean) => {
        if (confirmed) {
          this.userService.deleteUser(id).subscribe({
            next: () => {
              // send success notification
              this.notificationService.setNotification = {
                type: 'success',
                message: 'Der Benutzer wurde erfolgreich gelöscht',
              };
            },
            error: () => {
              this.notificationService.setNotification = {
                type: 'error',
                message: 'Es ist ein Fehler aufgetreten',
              };
            },
          });
        }
      },
    });
  }
}
