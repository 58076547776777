import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { map, Observable, tap } from 'rxjs';
import { NotificationService } from '../providers/notification.service';
import { QuestionService } from '../providers/question.service';

@Injectable({ providedIn: 'root' })
export class QuestionGuard implements CanActivate {
  constructor(
    private router: Router,
    private questionService: QuestionService,
    private notificationService: NotificationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const id = route.paramMap.get('id');
    return this.questionService.getQuestion(id).pipe(
      tap((question) => {
        if (!question) {
          this.notificationService.setNotification = {
            type: 'error',
            message: 'Ein Fehler ist aufgetreten',
          };
          this.router.navigate(['']);
        }
      }),
      map((question) => !!question)
    );
  }
}
