import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Session } from '../models/session.model';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private restServerUrl: string;
  private sessions: BehaviorSubject<Session[]> = new BehaviorSubject(null);

  constructor(private http: HttpClient) {
    this.restServerUrl = environment.apiServer;

    // initial sessions fetch
    this.getSessions().subscribe();
  }

  // ---------------------------------------------------------------------------------------------
  // @ Getters and setters
  // ---------------------------------------------------------------------------------------------

  /**
   * Getter for sessions
   */
  public get sessions$(): Observable<Session[]> {
    return this.sessions.asObservable();
  }

  // ---------------------------------------------------------------------------------------------
  // @ Public methods
  // ---------------------------------------------------------------------------------------------

  /**
   * Gets the list of sessions from the database.
   * @returns Observable of sessions
   */
  public getSessions(): Observable<Session[]> {
    return this.http
      .get<Session[]>(this.restServerUrl + '/api/s/a/list')
      .pipe(tap((sessions) => this.sessions.next(sessions)));
  }

  /**
   * Gets a single session based on it's id.
   * @param id sessionId as string
   * @returns Observable of the requested session
   */
  public getSession(id: string): Observable<Session> {
    return this.http.get<Session>(this.restServerUrl + '/api/s/a/' + id);
  }
}
