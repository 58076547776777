import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpErrorResponse
  } from "@angular/common/http";
  import { catchError } from "rxjs/operators";
  import { throwError } from "rxjs";
  import { Injectable } from "@angular/core";
  import { MatSnackBar } from '@angular/material/snack-bar';
  
  
  
  @Injectable()
  export class ErrorInterceptor implements HttpInterceptor {
  
    constructor(
      public snackBar: MatSnackBar,
    ) {}
  
    intercept(req: HttpRequest<any>, next: HttpHandler) {
      return next.handle(req).pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = "Ein Fehler ist aufgetreten!";
          if (error.error.message) {
            errorMessage = error.error.message;
            // Fire snack bar
            // this.snackBar.open(errorMessage, "",{
            //     duration: 3000,
            //     horizontalPosition: 'right'
            // });
          }
          // this.errorService.throwError(errorMessage);
          return throwError(error);
        })
      );
    }
  }
  
  