<div class="content-wrap">
  <h1>Abrechnung</h1>

  <mat-card>
    <mat-card-content>
      <mat-form-field>
        <mat-label>Jahr</mat-label>
        <mat-select [(value)]="selectedYear">
          <mat-option *ngFor="let year of possibleYears" [value]="year">{{
            year
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Monat</mat-label>
        <mat-select [(value)]="selectedMonth">
          <mat-option value="01">Januar</mat-option>
          <mat-option value="02">Februar</mat-option>
          <mat-option value="03">März</mat-option>
          <mat-option value="04">April</mat-option>
          <mat-option value="05">Mai</mat-option>
          <mat-option value="06">Juni</mat-option>
          <mat-option value="07">Juli</mat-option>
          <mat-option value="08">August</mat-option>
          <mat-option value="09">September</mat-option>
          <mat-option value="10">Oktober</mat-option>
          <mat-option value="11">November</mat-option>
          <mat-option value="12">Dezember</mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-flat-button color="primary" (click)="getSettlements()">
        Anwenden
      </button>
    </mat-card-content>
  </mat-card>

  <ng-container
    *ngIf="settlements && settlements.data.length; else NoSettlements"
  >
    <table mat-table [dataSource]="settlements" class="mat-elevation-z8">
      <!-- Amount Column -->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Betrag</th>
        <td mat-cell *matCellDef="let element">
          {{ element.totalEarnedCent }}
        </td>
      </ng-container>

      <!-- Firstname Column -->
      <ng-container matColumnDef="firstname">
        <th mat-header-cell *matHeaderCellDef>Vorname</th>
        <td mat-cell *matCellDef="let element">
          {{ element.craftsman.firstname }}
        </td>
      </ng-container>

      <!-- Lastname Column -->
      <ng-container matColumnDef="lastname">
        <th mat-header-cell *matHeaderCellDef>Nachname</th>
        <td mat-cell *matCellDef="let element">
          {{ element.craftsman.lastname }}
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Aktionen</th>
        <td mat-cell *matCellDef="let element">
          <button mat-button color="primary">Ausgezahlt</button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </ng-container>

  <ng-template #NoSettlements>
    <mat-card>
      <mat-card-content>
        <p>Für diesen Monat existieren keine Abrechnungsdaten</p>
      </mat-card-content>
    </mat-card>
  </ng-template>
</div>
