import { Injectable, NgZone } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { Subject, BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { takeUntil } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from "@angular/common";

export interface AuthData {
	email?: string;
	firstname?: string;
	lastname?: string;
	password: string;
	role?: string;
	device?: string;
}

@Injectable({ providedIn: "root" })
export class AuthService {
    private restServerUrl;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private authStatusListener = new Subject<boolean>();
    private token: any;
    private user: any;
    private isAuthenticated = false;

    constructor(
		private router: Router,
        private __zone: NgZone,
		private http: HttpClient,
		private location: Location,
        public snackBar: MatSnackBar,
	) {
		this.restServerUrl = environment.apiServer ;
    }
    
    getToken() {
		return this.token;
	}
	
	getAuthStatusListener() {
		return this.authStatusListener.asObservable();
	}

    // User login
	login(email: string, password: string, rememberMe: boolean) {
		const authData: AuthData = { email: email, password: password, device: 'web' };
		this.http
			.post<{ token: string; exp: number, user: any }>(
				this.restServerUrl + "/api/u/login",
				authData
			).pipe(
				takeUntil(this._unsubscribeAll)
			)
			.subscribe(response => {
				console.log(response.user.role);
				if(response.user.role == "admin") {
					console.log(response)
					const user = response.user;
					this.user = user;
					const token = response.token;
					this.token = token;
					const expirationDateUnix = response.exp;                    // expiration Date as Unix timestamp
					const expirationDate = new Date(expirationDateUnix * 1000)  // JavaScript-Time is in Milliseconds
					this.setUser(user, token, rememberMe);
					this.router.navigate(["/question-list"]);
				} else {
					let errorText = "Benuter ist kein Admin";
					this.snackBar.open(errorText, "",{
						panelClass: 'snack-error',
						duration: 10000,
						horizontalPosition: 'right'
					});
				}
			}, error => {
				this.authStatusListener.next(false);
				this.__zone.run(() => {
					console.log(error.status)
					let errorText = "Benutzernamen/Passwort fehlerhaft";
					if (error.status == 0) {
						errorText = "Keine Verbindung zum Server.";
					}
					this.snackBar.open(errorText, "",{
						panelClass: 'snack-error',
						duration: 3000,
						horizontalPosition: 'right'
					});
				});
			});
    }
    
    setUser(user, token, rememberMe) {
		if (token) {
			this.saveAuthData(token, user, rememberMe); // Save Auth Data to localStorage
			this.isAuthenticated = true;
			this.authStatusListener.next(true);
			// const now = new Date()
			// // const expiresIn = expirationDate.getTime() - now.getTime()  // Duration to expiration in Milliseconds
			// console.log("expiresIn: " + expiresIn + " Milliseconds")
			// this.setAuthTimer(expiresIn);   
		}    
    }

    
	getIsAuth() {
		return this.isAuthenticated;
	}

    setAuthenticatedStatus() {
		this.authStatusListener.next(this.getIsAuth());
	}
	
    
    // Save Auth Data in Browsers localStorage
	saveAuthData(token: string,  user: any, rememberMe: boolean) {
		this.user = user;
		localStorage.setItem("user", JSON.stringify(user));
		localStorage.setItem("token", token);
		if (rememberMe) {
			localStorage.setItem("token", token);
		} else {
			sessionStorage.setItem("token", token);
		}
		// localStorage.setItem("expiration", expirationDate.toISOString()); // localStorage can only save Strings
	}

	// Get localStorage Auth Data
	private getAuthData() {
		const token =
		localStorage.getItem('token') || sessionStorage.getItem('token');
	  const user = JSON.parse(
		localStorage.getItem('user') || sessionStorage.getItem('user')
	  );
	  if (!token) {
		return;
	  }
	  return {
		token: token,
		user: user
	  };
	}

	autoAuthUser() {
		const authInformation = this.getAuthData();
		console.log(authInformation)
		if (!authInformation) {
			let currentLocation = this.location.path();
			if (currentLocation.includes('/reset-password')) {
			} else {
				this.router.navigate(["/login"]);
			}
			return;
		}
			this.token = authInformation.token;
			this.user = authInformation.user;
			this.isAuthenticated = true;
			// this.setAuthTimer(expiresIn);
			this.authStatusListener.next(true);
	}

	logout() {
		this.isAuthenticated = false;
		this.authStatusListener.next(this.isAuthenticated);
		this.clearAuthData();
		this.router.navigate(["/login"]);
	}

	// Clear Auth Data from Browsers localStorage
	private clearAuthData() {
		localStorage.removeItem("token");
		sessionStorage.removeItem("token");
		localStorage.removeItem("user");
	}
	
}


