import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { FormGroup, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  private restServerUrl;
  loginForm: FormGroup;
  appSettings;
  showInstructionVideos: boolean = true;

  private _unsubscribeAll: Subject<any> = new Subject<any>();


  constructor(
    private router: Router,
    private authService : AuthService,
  ) {
    this.restServerUrl = environment.apiServer;
   }

  ngOnInit() {
    if (this.authService.getIsAuth()) {
      this.router.navigate(['dashboard']);
    } 
  }

  /**
   *  login
   */
  login(form: NgForm) {
    if (form.invalid) {
      return;
    }
    this.authService.login(form.value.email, form.value.password, form.value.rememberMe);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
