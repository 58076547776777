import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Question, Craft } from 'src/app/models/question.model';
import { NotificationService } from 'src/app/providers/notification.service';
import { QuestionService } from 'src/app/providers/question.service';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';

@Component({
  selector: 'app-question-list',
  templateUrl: './question-list.component.html',
  styleUrls: ['./question-list.component.scss'],
})
export class QuestionListComponent implements OnInit, OnDestroy {
  questions: Question[];
  filteredQuestions: Question[];
  crafts: Craft[];
  selectedCraft: string = '';
  private unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private questionService: QuestionService
  ) {}

  // ---------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // ---------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    // Preloaded questions and then listening for changes
    this.questions = this.route.snapshot.data["questions"];
    this.filteredQuestions = this.questions;
    this.questionService.questions$
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (questions) => {
          this.questions = questions;
          this.filterQuestions();
        },
      });

    // Preloaded crafts
    this.crafts = this.route.snapshot.data["crafts"];
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.unsubscribeAll.complete();
  }

  // ---------------------------------------------------------------------------------------------
  // @ Private methods
  // ---------------------------------------------------------------------------------------------

  /**
   * Filter the questions based on the selected craft
   */
  private filterQuestions() {
    // if the user deselected the selected craft or if it's not selected
    if (this.selectedCraft === 'none' || !this.selectedCraft) {
      this.selectedCraft = ''; // reset to placeholder
      this.filteredQuestions = this.questions;
      return;
    }
    this.filteredQuestions = this.questions.filter((question) => {
      return question.craft.some(craft => {
        return craft === this.selectedCraft; 
      } );
    });
  }

  // ---------------------------------------------------------------------------------------------
  // @ Public methods
  // ---------------------------------------------------------------------------------------------

  /**
   * OnChange listener for craft select
   */
  onCraftChange() {
    this.filterQuestions();
  }

  /**
   * Formats a question and replaces all characters after 30 characters with `...`
   * @param question
   * @returns
   */
  formatQuestion(question: string): string {
    if (question.length <= 100) return question;

    // trim if too long
    return question.substring(0, 100) + '...';
  }

  /**
   * Formats the craftSlug to the craft name
   * @param craftSlug
   * @returns
   */
  formatCraft(craftSlug: any): any {
    //TODO
    // console.log(craftSlug.split())
    // return this.crafts.find((craft) => craft.slug == craftSlug).name;
    return this.crafts.find((craft) => {
        craftSlug.forEach(element => {
        craft.slug == element
      })
    });
  }

  /**
   * Delete a question based on it's id
   * @param id the selected question id
   */
  delete(id: string) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '250px',
      data: { message: 'Möchten Sie diese Frage wirklich löschen?' },
    });

    // if confirmed, delete the question and send a notification
    dialogRef.afterClosed().subscribe({
      next: (confirmed: boolean) => {
        if (confirmed) {
          this.questionService.deleteQuestion(id).subscribe({
            next: () => {
              // send success notification
              this.notificationService.setNotification = {
                type: 'success',
                message: 'Die Frage wurde erfolgreich gelöscht',
              };
            },
            error: () => {
              this.notificationService.setNotification = {
                type: 'error',
                message: 'Es ist ein Fehler aufgetreten',
              };
            },
          });
        }
      },
    });
  }
}
