<div class="content-wrap">
  <ng-container>
    <h2>Benutzer bearbeiten</h2>
    <form [formGroup]="userForm" #formDirective="ngForm">
      <!-- Email input -->
      <mat-form-field>
        <input
          matInput
          formControlName="email"
          type="text"
          placeholder="E-Mail"
        />
        <mat-error
          *ngIf="
            (userForm.get('email').dirty || userForm.get('email').touched) &&
            userForm.get('email').errors
          "
        >
          E-Mail notwendig
        </mat-error>
      </mat-form-field>

      <!-- Role input -->
      <mat-form-field>
        <mat-label>Benutzerrolle</mat-label>
        <mat-select formControlName="role" placeholder="Benutzerrolle">
          <mat-option value="diy">Anwender</mat-option>
          <mat-option value="craftsman">Handwerker</mat-option>
          <mat-option value="admin">Admin</mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            (userForm.get('role').dirty || userForm.get('role').touched) &&
            userForm.get('role').errors
          "
        >
          Benutzerrolle notwendig
        </mat-error>
      </mat-form-field>

      <ng-container *ngIf="isCraftsman || isAdmin">
        <div class="input-group">
          <!-- First name input -->
          <mat-form-field>
            <input
              matInput
              formControlName="firstname"
              type="text"
              placeholder="Vorname"
            />
            <mat-error
              *ngIf="
                (userForm.get('firstname').dirty ||
                  userForm.get('firstname').touched) &&
                userForm.get('firstname').errors
              "
            >
              Vorname notwendig
            </mat-error>
          </mat-form-field>

          <!-- Last name input -->
          <mat-form-field>
            <input
              matInput
              formControlName="lastname"
              type="text"
              placeholder="Nachname"
            />
            <mat-error
              *ngIf="
                (userForm.get('lastname').dirty ||
                  userForm.get('lastname').touched) &&
                userForm.get('lastname').errors
              "
            >
              Nachname notwendig
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Craft input -->
        <table
          mat-table
          [dataSource]="userCrafts"
          class="crafts-table"
          *ngIf="isCraftsman"
        >
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Gewerk</th>
            <td mat-cell *matCellDef="let craft">{{ craft.name }}</td>
          </ng-container>

          <ng-container matColumnDef="docValidation">
            <th mat-header-cell *matHeaderCellDef>docValidation</th>
            <td mat-cell *matCellDef="let craft">
              <div class="craft-validation-field">
                <ng-container *ngIf="craft.docValidation.activated != null; else notValidated">{{
                  craft.docValidation.activated | date : "dd.MM.yyyy"
                }}</ng-container>
                <ng-template #notValidated>
                  false
                  <mat-icon (click)="validateDoc(craft.name)"
                    >check_circle</mat-icon
                  >
                </ng-template>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="examValidation">
            <th mat-header-cell *matHeaderCellDef>examValidation</th>
            <td mat-cell *matCellDef="let craft">
              <div class="craft-validation-field">
                <ng-container *ngIf="craft.examValidation.activated != null; else notValidated">{{
                  craft.examValidation.activated | date : "dd.MM.yyyy"
                }}</ng-container>
                <ng-template #notValidated>
                  false
                  <mat-icon (click)="validateExam(craft.name)"
                    >check_circle</mat-icon
                  >
                </ng-template>
              </div>
            </td>
          </ng-container>

          <!-- <ng-container matColumnDef="addressValidation">
            <th mat-header-cell *matHeaderCellDef>addressValidation</th>
            <td mat-cell *matCellDef="let craft">
              <div class="craft-validation-field">
                <ng-container
                  *ngIf="craft.addressValidation; else notValidated"
                  >{{
                    craft.addressValidation | date : "dd.MM.yyyy"
                  }}</ng-container
                >
                <ng-template #notValidated>
                  false
                  <mat-icon (click)="validateAddress(craft.name)"
                    >check_circle</mat-icon
                  >
                </ng-template>
              </div>
            </td>
          </ng-container> -->

          <ng-container matColumnDef="validated">
            <th mat-header-cell *matHeaderCellDef>validated</th>
            <td mat-cell *matCellDef="let craft">
              <div class="craft-validation-field">
                <ng-container *ngIf="craft.validated; else notValidated">
                  true
                </ng-container>
                <ng-template #notValidated>
                  false
                  <mat-icon (click)="validate(craft.name)"
                    >check_circle</mat-icon
                  >
                </ng-template>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <h2>Adresse</h2>
        <div class="input-group">
          <!-- PLZ input -->
          <mat-form-field>
            <input
              matInput
              formControlName="plz"
              type="text"
              placeholder="PLZ"
            />
          </mat-form-field>

          <!-- city input -->
          <mat-form-field>
            <input
              matInput
              formControlName="city"
              type="text"
              placeholder="Stadt"
            />
          </mat-form-field>
        </div>

        <!-- Street Nr input -->
        <mat-form-field>
          <input
            matInput
            formControlName="streetNr"
            type="text"
            placeholder="Straße und Hausnummer"
          />
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="isCraftsman">
        <h2>Unternehmensinformationen</h2>

        <ng-container formGroupName="business">
          <!-- Firstname input -->
          <mat-form-field>
            <input
              matInput
              formControlName="firstname"
              type="text"
              placeholder="Vorname des Rechnungsaddressenten"
            />
          </mat-form-field>

          <!-- Lastname input -->
          <mat-form-field>
            <input
              matInput
              formControlName="lastname"
              type="text"
              placeholder="Nachname des Rechnungsaddressenten"
            />
          </mat-form-field>

          <!-- Company name input -->
          <mat-form-field>
            <input
              matInput
              formControlName="company"
              type="text"
              placeholder="Name des Unternehmens"
            />
          </mat-form-field>

          <!-- street and house number input -->
          <mat-form-field>
            <input
              matInput
              formControlName="streetNr"
              type="text"
              placeholder="Straße und Hausnummer des Unternehmens"
            />
          </mat-form-field>

          <!-- PlZ input -->
          <mat-form-field>
            <input
              matInput
              formControlName="plz"
              type="text"
              placeholder="PLZ des Unternehmens"
            />
          </mat-form-field>

          <!-- City input -->
          <mat-form-field>
            <input
              matInput
              formControlName="city"
              type="text"
              placeholder="Stadt des Unternehmens"
            />
          </mat-form-field>

          <!-- Tax id input -->
          <mat-form-field>
            <input
              matInput
              formControlName="umStId"
              type="text"
              placeholder="UmStID / Steuernummer"
            />
          </mat-form-field>

          <div class="address-validation-row">
            <p>addressValidation</p>
            <div class="craft-validation-field">
              <ng-container
                *ngIf="userBusiness?.activated != null ; else notValidated"
                >{{
                  userBusiness.activated | date : "dd.MM.yyyy"
                }}</ng-container
              >
              <ng-template #notValidated>
                false
                <mat-icon (click)="validateAddress(userBusiness)"
                  >check_circle</mat-icon
                >
              </ng-template>
            </div>
          </div>
        </ng-container>

        <h2>Optionale Handwerker Informationen</h2>
        <div class="input-group">
          <!-- Language input -->
          <mat-form-field>
            <mat-label>Sprachen</mat-label>
            <mat-select
              formControlName="languages"
              placeholder="Sprachen"
              multiple
            >
              <mat-option
                *ngFor="let language of languages"
                [value]="language.abbreviation"
              >
                {{ language.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Description Field -->
        <mat-form-field>
          <textarea
            matInput
            formControlName="description"
            placeholder="Steckbrief"
            rows="6"
          ></textarea>
        </mat-form-field>

        <mat-form-field>
          <mat-chip-list #chipList>
            <mat-chip
              *ngFor="let fieldTag of userFieldTags"
              (removed)="removeFieldTag(fieldTag)"
              >{{ fieldTag
              }}<button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button></mat-chip
            >
            <input
              type="text"
              placeholder="Erfahrung hinzufügen..."
              [matAutocomplete]="auto"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              formControlName="fieldTags"
              (matChipInputTokenEnd)="addFieldTag($event)"
            />
            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="selectFieldTag($event)"
            >
              <mat-option
                *ngFor="let fieldTag of filteredFieldTags | async"
                [value]="fieldTag"
              >
                {{ fieldTag }}
              </mat-option>
            </mat-autocomplete>
          </mat-chip-list>
        </mat-form-field>
      </ng-container>

      <div class="button-wrap">
        <button mat-flat-button color="primary" (click)="onSubmit()">
          Speichern
        </button>
      </div>
    </form>
  </ng-container>
</div>
