import { Component, OnInit } from '@angular/core';
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  Notification,
  NotificationService,
} from './providers/notification.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  userIsAuthenticated: boolean = false;
  private authListenerSubs: Subscription;

  constructor(
    public router: Router,
    private authService: AuthService,
    public authGuard: AuthGuard,
    private notificationService: NotificationService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
   // this.userIsAuthenticated = this.authService.getIsAuth();
    this.authService.autoAuthUser();
    this.authListenerSubs = this.authService
      .getAuthStatusListener()
      .subscribe((isAuthenticated) => {
        if (isAuthenticated) {
          this.userIsAuthenticated = isAuthenticated;
        }
      });

    // appwide notifications
    this.notificationService.notification$.subscribe({
      next: (notification: Notification) => {
        this.snackBar.open(notification.message, '', {
          duration: 5000,
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
          panelClass: notification.type,
        });
      },
    });
  }
}
