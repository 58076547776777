import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * Notification datatype to use with the NotificationService.
 */
export type Notification = {
    type: 'success' | 'error';
    message: string;
};

@Injectable({ providedIn: 'root' })
export class NotificationService {
    private notifaction: Subject<Notification> = new Subject();

    // ---------------------------------------------------------------------------------------------
    // @ Getters & Setters
    // ---------------------------------------------------------------------------------------------

    /**
     * Get notification
     * @returns Observable<Notification>
     */
    public get notification$(): Observable<Notification> {
        return this.notifaction.asObservable();
    }

    /**
     * Set the Notification
     *
     * @param notification The notification using the `Notification` type.
     */
    public set setNotification(notification: Notification) {
        this.notifaction.next(notification);
    }
}
