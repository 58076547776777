import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Answer, Question, Craft } from 'src/app/models/question.model';
import { QuestionService } from 'src/app/providers/question.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/providers/notification.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-question-edit',
  templateUrl: './question-edit.component.html',
  styleUrls: ['./question-edit.component.scss'],
})
export class QuestionEditComponent implements OnInit, OnDestroy {
  crafts: Craft[];
  question: Question;
  questionForm: FormGroup;
  hasCorrectAnswer: boolean = true;
  private unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private questionService: QuestionService,
    private notificationService: NotificationService,
    private formBuilder: FormBuilder
  ) {}

  // ---------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // ---------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.crafts = this.route.snapshot.data['crafts'];
    this.question = this.route.snapshot.data['question'];

    // prefilled questionForm
    this.questionForm = this.formBuilder.group({
      craft: [this.question.craft, [Validators.required]],
      question: [this.question.question, [Validators.required]],
      answers: this.formBuilder.array([]),
    });

    // prefill answers
    this.question.answers.forEach((answer) => {
      this.addAnswer(answer);
    });
  }

  /**
   * On delete
   */
  ngOnDestroy(): void {
    this.unsubscribeAll.complete();
  }

  // ---------------------------------------------------------------------------------------------
  // @ Getters and Setters
  // ---------------------------------------------------------------------------------------------

  /**
   * Gets the answers Formarrays for dynamic rendering
   */
  get answers(): FormArray {
    return this.questionForm.get('answers') as FormArray;
  }

  // ---------------------------------------------------------------------------------------------
  // @ Private methods
  // ---------------------------------------------------------------------------------------------

  /**
   * Checks if at least one answer is marked as correct in the answers
   * @returns result on existing correct answer as boolean
   */
  private checkForCorrectAnswer(): boolean {
    const answers = this.questionForm.get('answers').value;

    // check that at least one answer is marked as correct
    let hasCorrect = false;
    answers.forEach((answerForm: Answer) => {
      if (answerForm.rightAnswer == true) hasCorrect = true;
    });
    this.hasCorrectAnswer = hasCorrect;
    return hasCorrect;
  }

  // ---------------------------------------------------------------------------------------------
  // @ Public methods
  // ---------------------------------------------------------------------------------------------

  /**
   * add an answer form to the form
   */
  addAnswer(defaultAnswer?: Answer) {
    const answerText = (defaultAnswer && defaultAnswer.answer) ?? '';
    const answersRightAnswer =
      (defaultAnswer && defaultAnswer.rightAnswer) ?? false;

    const answerForm = this.formBuilder.group({
      answer: [answerText, [Validators.required]],
      rightAnswer: [answersRightAnswer, [Validators.required]],
    });
    answerForm.controls.rightAnswer.valueChanges
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (val: boolean) => {
          if (val) this.hasCorrectAnswer = true;
        },
      });
    this.answers.push(answerForm);
  }

  /**
   * Removes the answer form at a given index
   * @param index index that should be removed
   */
  removeAnswer(index: number) {
    this.answers.removeAt(index);
  }

  /**
   * Submits the question
   *
   * Fails if the form is invalid or if none of the entered answers is marked as
   * correct. Redirects to the question-list if successful.
   */
  onSubmit() {
    if (this.questionForm.invalid) {
      this.questionForm.markAllAsTouched();
      this.hasCorrectAnswer = false;
      return;
    }
    if (!this.checkForCorrectAnswer()) {
      return;
    }
    const formValues = this.questionForm.value;

    this.questionService
      .updateQuestion(this.question._id, formValues)
      .subscribe({
        next: () => {
          this.notificationService.setNotification = {
            type: 'success',
            message: 'Die Frage wurde erfolgreich bearbeitet',
          };
          this.router.navigate(['/question-list']);
        },
        error: () => {
          this.notificationService.setNotification = {
            type: 'error',
            message: 'Es ist ein Fehler aufgetreten',
          };
        },
      });
  }
}
