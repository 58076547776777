import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Question, Craft } from '../models/question.model';

@Injectable({
  providedIn: 'root',
})
export class QuestionService {
  private restServerUrl;
  private questions: BehaviorSubject<Question[]> = new BehaviorSubject(null);
  private crafts: BehaviorSubject<Craft[]> = new BehaviorSubject(null);

  constructor(private http: HttpClient) {
    this.restServerUrl = environment.apiServer;

    // get them for the first time
    this.getCrafts().subscribe();
  }

  // ---------------------------------------------------------------------------------------------
  // @ Getters
  // ---------------------------------------------------------------------------------------------

  /**
   * Getter for questions
   */
  public get questions$(): Observable<Question[]> {
    return this.questions.asObservable();
  }

  /**
   * Getter for crafts
   */
  public get crafts$(): Observable<Craft[]> {
    return this.crafts.asObservable();
  }

  // ---------------------------------------------------------------------------------------------
  // @ Public methods
  // ---------------------------------------------------------------------------------------------

  /**
   * Gets a list of crafts with their slug
   * @returns Observable of all crafts
   */
  public getCrafts(): Observable<Craft[]> {
    return this.http.get<Craft[]>(this.restServerUrl + '/api/crafts').pipe(
      tap((crafts) => {
        this.crafts.next(crafts);
      })
    );
  }

  /**
   * Create a question.
   * @param question the new question
   * @returns Observable of the created question
   */
  public createQuestion(question: Question): Observable<Question> {
    return this.http.post<Question>(
      this.restServerUrl + '/api/q/a/create',
      question
    );
  }

  /**
   * Gets the list of questions from the database.
   * @returns Observable of questions
   */
  public getQuestions(): Observable<Question[]> {
    return this.http.get<Question[]>(this.restServerUrl + '/api/q/a/list').pipe(
      tap((questions) => {
        this.questions.next(questions);
      })
    );
  }

  /**
   * Gets a single question based on it's id.
   * @param id questionId as string
   * @returns Observable of the requested question
   */
  public getQuestion(id: string): Observable<Question> {
    return this.http.get<Question>(this.restServerUrl + '/api/q/a/' + id);
  }

  /**
   * Update a single question based on it's id.
   * @param id questionId as string
   * @param question updated question
   * @returns Observable of the to updating question
   */
  public updateQuestion(id: string, question: Question): Observable<Question> {
    return this.http.put<Question>(
      this.restServerUrl + '/api/q/a/' + id,
      question
    );
  }

  /**
   * Delete a question based on it's id.
   * @param id questionId as string
   * @returns Observable of the deleted question
   */
  public deleteQuestion(id: string): Observable<Question> {
    return this.http
      .delete<Question>(this.restServerUrl + '/api/q/a/' + id)
      .pipe(tap(() => this.getQuestions().subscribe()));
  }
}
