<div class="content-wrap">
  <h1>Liste aller Benutzer</h1>
  <div class="user-list__add-button">
    <button mat-mini-fab color="primary" [routerLink]="['create']">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <!-- User List -->
  <mat-grid-list cols="12">
    <ng-container *ngFor="let user of users" class="user-list__container">
      <mat-grid-tile colspan="4">
        <div class="user-list__item user-list__email">
          {{ user.email }}
        </div>
      </mat-grid-tile>
      <mat-grid-tile colspan="2">
        <div class="user-list__item">
          {{ user.firstname }} {{ user.lastname }}
        </div>
      </mat-grid-tile>
      <mat-grid-tile class="craft_list" colspan="2">
        <div class="user-list__item craft" >
         <p *ngFor="let craft of user?.craft">  {{ craft.name }}</p>
        </div>
      </mat-grid-tile>
      <mat-grid-tile colspan="2">
        <div class="user-list__item">
          {{ formatRole(user.role) }}
        </div>
      </mat-grid-tile>
      <mat-grid-tile colspan="2">
        <div>
          <button mat-button color="primary" [routerLink]="['edit', user._id]">
            Bearbeiten
          </button>
          <button mat-button color="warn" (click)="delete(user._id)">
            Löschen
          </button>
        </div>
      </mat-grid-tile>
    </ng-container>
  </mat-grid-list>
</div>
