import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/providers/notification.service';
import { map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import {
  Language,
  Craft,
  User,
  UserRole,
  UserCraft,
} from 'src/app/models/user.model';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { UserService } from 'src/app/providers/user.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-user-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class UserEditComponent implements OnInit, OnDestroy {
  crafts: Craft[];
  fieldTags: string[];
  languages: Language[];
  userId: string;
  userCrafts: UserCraft[];
  displayedColumns = [
    'name',
    'docValidation',
    'examValidation',
   // 'addressValidation',
    'validated',
  ];
  userForm: FormGroup;
  sendValidationMail = false;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  userFieldTags: string[] = [];
  filteredFieldTags: Observable<string[]>;
  private unsubscribeAll: Subject<any> = new Subject<any>();
  userBusiness;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private userService: UserService,
    private formBuilder: FormBuilder
  ) {}

  // ---------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // ---------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    // get resolved crafts
    this.crafts = this.route.snapshot.data['crafts'];

    // get resolved fieldTags
    this.fieldTags = this.route.snapshot.data['fieldTags'];

    // get resolved user
    const user = this.route.snapshot.data['user'] as User;
    this.userId = user._id;
    this.userFieldTags = user.fieldTags;
    this.userCrafts = user.craft;
    this.userBusiness = user.business;

    // dummy languages
    this.languages = [
      { name: 'Deutsch', abbreviation: 'de' },
      { name: 'Englisch', abbreviation: 'en' },
      { name: 'Spanisch', abbreviation: 'es' },
      { name: 'Französisch', abbreviation: 'fr' },
    ];

    // prefilled questionForm
    this.userForm = this.formBuilder.group({
      email: [user.email, [Validators.required, Validators.email]],
      role: [user.role, [Validators.required]],
      firstname: [user.firstname, []],
      lastname: [user.lastname, []],
      streetNr: [user.streetNr, []],
      plz: [user.plz, []],
      city: [user.city, []],
      languages: [user.languages, []],
      description: [user.description, []],
      fieldTags: ['', []],
      business: this.formBuilder.group({
        firstname: [user.business?.firstname ?? '', []],
        lastname: [user.business?.lastname ?? '', []],
        company: [user.business?.company ?? '', []],
        streetNr: [user.business?.streetNr ?? '', []],
        plz: [user.business?.plz ?? '', []],
        city: [user.business?.city ?? '', []],
        umStId: [user.business?.umStId ?? '', []],
      }),
    });

    // change validators based on role
    if (user.role === 'craftsman') this.setCraftsmanValidation(user.role);
    this.userForm.controls.role.valueChanges.subscribe({
      next: (role: UserRole) => {
        this.setCraftsmanValidation(role);
      },
    });

    // subscribe to fieldTag field and listen for changes
    this.filteredFieldTags = this.userForm.controls.fieldTags.valueChanges.pipe(
      takeUntil(this.unsubscribeAll),
      startWith(null),
      map((tag: string | null) =>
        tag ? this.filterFieldTags(tag) : this.fieldTags.slice()
      )
    );
  }

  /**
   * On delete
   */
  ngOnDestroy(): void {
    this.unsubscribeAll.complete();
  }

  // ---------------------------------------------------------------------------------------------
  // @ Getters and Setters
  // ---------------------------------------------------------------------------------------------

  /**
   * Check if new user is supposed to be a craftsman
   * @returns true if the user is a craftsman
   */
  public get isCraftsman(): boolean {
    return this.userForm.value.role === 'craftsman';
  }

  /**
   * Check if new user is supposed to be an admin
   * @returns true if the user is an admin
   */
  public get isAdmin(): boolean {
    return this.userForm.value.role === 'admin';
  }

  // ---------------------------------------------------------------------------------------------
  // @ Private methods
  // ---------------------------------------------------------------------------------------------

  /**
   * Filter out field tags based on the current input
   * @param value fieldTag as `string`
   * @returns `String array` of the filterec field tags
   */
  private filterFieldTags = (value: string): string[] => {
    const filterValue = value.toLowerCase();
    return this.fieldTags.filter((tag) =>
      tag.toLowerCase().includes(filterValue)
    );
  };

  /**
   * Set or clear craftsman validation if the new user is supposed to be a craftsman
   * @param role Role of the user as `UserRole`
   */
  private setCraftsmanValidation = (role: UserRole) => {
    const formControls = this.userForm.controls;
    const firstname = formControls.firstname;
    const lastname = formControls.lastname;

    if (role === 'admin' || role === 'diy') {
      firstname.clearValidators();
      lastname.clearValidators();
    } else {
      firstname.addValidators([Validators.required]);
      firstname.markAsPristine();
      firstname.markAsUntouched();
      lastname.addValidators([Validators.required]);
      lastname.markAsPristine();
      lastname.markAsUntouched();
    }
    firstname.updateValueAndValidity();
    lastname.updateValueAndValidity();
  };

  // ---------------------------------------------------------------------------------------------
  // @ Public methods
  // ---------------------------------------------------------------------------------------------

  /**
   * Add a field tag to the users field tags if the field tag has more than 3
   * characters.
   *
   * Displays an error notification if the field tag to acc has less than 3
   * characters.
   * @param event
   */
  addFieldTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value.length < 3) {
      this.notificationService.setNotification = {
        type: 'error',
        message:
          'Ein Erfahsungsbereich muss aus mindestens 3 Zeichen bestehen.',
      };
      return;
    }
    this.userFieldTags.push(value);
    event.chipInput!.clear();
  }

  /**
   * Removes a field tag from the users field tags
   * @param tag
   */
  removeFieldTag(tag: string): void {
    const index = this.userFieldTags.indexOf(tag);
    if (index >= 0) {
      this.userFieldTags.splice(index, 1);
    }
  }

  /**
   * Add a field tag based on the already existing and filtered field tags.
   * @param event
   */
  selectFieldTag(event: MatAutocompleteSelectedEvent): void {
    this.userFieldTags.push(event.option.viewValue);
    this.userForm.patchValue({
      fieldTags: '',
    });
  }

  /**
   * Set docValidation to current timestamp and update `validated` to `true` if examValidation is not null.
   * @param craftName modified craft
   */
  validateDoc(craftName: string): void {
    const index = this.userCrafts.findIndex(
      (craft) => craft.name === craftName
    );
    this.userCrafts[index].docValidation['activated'] = new Date();
    this.userCrafts[index].docValidation['submitted'] = true;
    if (
      this.userCrafts[index].examValidation["activated"] != null &&
      this.userBusiness["activated"] != null
    ) {
      this.sendValidationMail = true;
    }

    if (
      this.userCrafts[index].examValidation["activated"] != null &&
      this.userCrafts[index].docValidation["activated"] != null
    ) {
      this.userCrafts[index].validated = true;
    }
  }

  /**
   * Set examValidation to current timestamp and update `validated` to `true` if docValidation is not null.
   * @param craftName modified craft
   */
  validateExam(craftName: string): void {
    const index = this.userCrafts.findIndex(
      (craft) => craft.name === craftName
    );
    this.userCrafts[index].examValidation["activated"] = new Date();
     this.userCrafts[index].examValidation["submitted"] = true;
    if (
      this.userCrafts[index].docValidation["activated"] != null &&
      this.userBusiness["activated"] != null
    ) {
      this.sendValidationMail = true;
    }

    if (
      this.userCrafts[index].examValidation["activated"] != null &&
      this.userCrafts[index].docValidation["activated"] != null
    ) {
      this.userCrafts[index].validated = true;
    }
  }

  /**
   * Set examValidation to current timestamp and update `validated` to `true` if docValidation is not null.
   * @param craftName modified craft
   */
  validateAddress(userBusiness: string): void {
    userBusiness["activated"] = new Date();
    userBusiness["submitted"] = true;
    this.userCrafts.forEach(craftItem => {
        if (craftItem.docValidation["activated"] != null && craftItem.examValidation["activated"] != null) {
          this.sendValidationMail = true;
        }
      })
    // const index = this.userCrafts.findIndex(
    //   (craft) => craft.name === userBusiness
    // );
    // this.userCrafts[index].addressValidation = new Date();

    // if (
    //   this.userCrafts[index].docValidation["activated"] != null &&
    //   this.userCrafts[index].examValidation["activated"] != null
    // ) {
    //   this.sendValidationMail = true;
    //  // this.userCrafts[index].validated = true;
    // }
  }

  /**
   * Set `validated` to current timestamp and update both doc and examValidation to current timestamp if they are null.
   * @param craftName modified craft
   */
  validate(craftName: string): void {
    const index = this.userCrafts.findIndex(
      (craft) => craft.name === craftName
    );
    if (this.userCrafts[index].docValidation["activated"] == null) {
      this.userCrafts[index].docValidation["activated"] = new Date();
      this.userCrafts[index].docValidation["submitted"] = true;
    }
    if (this.userCrafts[index].examValidation["activated"] == null) {
      this.userCrafts[index].examValidation["activated"] = new Date();
      this.userCrafts[index].examValidation["submitted"] = true;
    }
    // if (this.userBusiness["activated"] != null) {
    //   this.userCrafts[index].addressValidation = new Date();
    // }
    this.userCrafts[index].validated = true;
    this.userCrafts.forEach(craftItem => {
      if (craftItem.docValidation["activated"] != null && craftItem.examValidation["activated"] != null) {
        this.sendValidationMail = true;
      }
    })

  }

  /**
   * Submits the updated user
   *
   * Fails if the form is invalid.
   * Redirects to user list if successful.
   */
  onSubmit() {
    if (this.userForm.invalid) {
      return;
    }
    const formValues = this.userForm.value;
    delete formValues.fieldTags;
    if (formValues.role === 'craftsman') {
      formValues.fieldTags = this.userFieldTags;
    }
    formValues.craft = this.userCrafts;
    formValues.business = this.userBusiness;

    // Only send Mail, the first time it becomes valid
    if (this.sendValidationMail) {
      this.userService.sendValidationMail(this.userId).subscribe({
        next: () => {},
        error: () => {
          this.notificationService.setNotification = {
            type: 'error',
            message: 'Es ist ein Fehler aufgetreten',
          };
        },
      });
    }

    this.userService.updateUser(this.userId, formValues).subscribe({
      next: () => {
        this.notificationService.setNotification = {
          type: 'success',
          message: 'Der Benutzer wurde erfolgreich bearbeitet',
        };
        this.router.navigate(['/users']);
      },
      error: () => {
        this.notificationService.setNotification = {
          type: 'error',
          message: 'Es ist ein Fehler aufgetreten',
        };
      },
    });
  }
}
