import { Injectable, PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { isPlatformBrowser } from '@angular/common';
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthGuard implements CanActivate {
	isBrowser;
	constructor(
		private authService: AuthService,
		private router: Router,
	) { };

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | Observable<boolean> | Promise<boolean> {
		const isAuth = this.authService.getIsAuth();
		console.log('canActivate: authenticated: ' + isAuth);
		if (!isAuth) {
			this.router.navigate(['/login']);
		};
		return isAuth;
	};
}
