<div class="content-wrap">
  <h1>Session Details</h1>
  <div class="session-details">
    <mat-card>
      <mat-card-header>
        <mat-card-title>General</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p><strong>Session ID:</strong> {{ session._id }}</p>
        <p><strong>Display ID:</strong> {{ session.displayId }}</p>
        <p><strong>Craftsman ID:</strong> {{ session._craftsman ?? "" }}</p>
        <p><strong>DIY ID:</strong> {{ session._diy ?? "" }}</p>
        <p>
          <strong>Erstellungs Zeitpunkt:</strong>
          {{ session.created | date : "dd.MM.yyyy - HH:mm:ss" }}
        </p>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-header>
        <mat-card-title>Zeiten</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          <strong>Start Zeitpunkt:</strong>
          {{ session.start | date : "dd.MM.yyyy - HH:mm:ss" }}
        </p>
        <p>
          <strong>End Zeitpunkt:</strong>
          {{ session.end | date : "dd.MM.yyyy - HH:mm:ss" }}
        </p>
        <p><strong>Dauer:</strong> {{ session.duration }} Sekunden</p>
        <p>
          <strong>Berechnete Dauer:</strong>
          {{ session.chargedDuration }} Sekunden
        </p>
        <p>
          <strong>Disconnected Dauer:</strong>
          {{ session.disconnectedTime }} Sekunden
        </p>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-header>
        <mat-card-title>Kosten</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          <strong>Kosten pro Minute:</strong>
          {{ session.costCentPerMinute }} Cent
        </p>
        <p>
          <strong>vSupport Provision:</strong>
          {{ session.vsupportProvision }}
        </p>
        <p>
          <strong>Gesamtkosten für DIY:</strong>
          {{ formatDIYCost(session.totalCostCent) }}
        </p>
        <p>
          <strong>Gesamteinnahmen für Handwerker:</strong>
          {{
            calculateCraftsmanIncome(
              session.totalCostCent,
              session.vsupportProvision
            )
          }}
          Cent
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-header>
        <mat-card-title>Notizen</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p><strong>Handwerker:</strong> {{ session.craftsmanNote }}</p>
        <p><strong>DIY:</strong> {{ session.diyNote }}</p>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-header>
        <mat-card-title>Bewertungen</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p><strong>Handwerker:</strong> {{ session.craftsmanRating }}</p>
        <p><strong>DIY:</strong> {{ session.diyRating }}</p>
      </mat-card-content>
    </mat-card>
  </div>
</div>
