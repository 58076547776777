import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Session } from 'src/app/models/session.model';
import { User } from 'src/app/models/user.model';
import { SessionService } from 'src/app/providers/session.service';

@Component({
  selector: 'app-session-list',
  templateUrl: './session-list.component.html',
  styleUrls: ['./session-list.component.scss'],
})
export class SessionListComponent implements OnInit, OnDestroy {
  displayedColumns = [
    'displayId',
    '_craftsman',
    '_diy',
    'start',
    'duration',
    'calcDuration',
    'costPerMinute',
    'totalCost',
    'totalIncome',
    'actions',
  ];
  users: User[];
  sessions: Session[];
  filteredOptions: Observable<string[]>;
  searchInput = new FormControl('');
  dataSource: MatTableDataSource<Session>;
  @ViewChild(MatSort) sort: MatSort;
  private unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private route: ActivatedRoute,
    private sessionService: SessionService
  ) {}

  // ---------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // ---------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    // Get sessions and users from resolver
    this.sessions = this.route.snapshot.data['sessions'];
    this.users = this.route.snapshot.data['users'];

    // Listen for changes from session
    this.sessionService.sessions$
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (sessions) => {
          this.sessions = sessions;
          this.filterSessions(sessions);
        },
      });

    // Subscribe to input changes
    this.searchInput.valueChanges.subscribe({
      next: (value) => {
        if (value.trim() === '') {
          this.filterSessions(this.sessions);
        }
        this.filterSessions(this.sessions, value);
      },
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.unsubscribeAll.complete();
  }

  // ---------------------------------------------------------------------------------------------
  // @ Private methods
  // ---------------------------------------------------------------------------------------------

  /**
   * Set the datasource based on the provided parameters.
   * @param sessions List of sessions
   */
  private setDataSource(sessions: Session[]) {
    this.dataSource = new MatTableDataSource(
      sessions.map((session) => {
        return {
          ...session,
          _craftsman: this.formatName(session._craftsman),
          _diy: this.formatName(session._diy),
        };
      })
    );
    this.dataSource.sort = this.sort;
  }

  /**
   * Filter the sessions based on the selected search querry
   * @param sessions List of sessions
   * @param searchQuery Query string (default:'')
   */
  private filterSessions(sessions: Session[], searchQuery: string = '') {
    if (searchQuery.trim() === '') {
      this.setDataSource(sessions);
      return;
    }

    const filteredSessions = this.sessions.filter((session) => {
      const isDisplayID = session.displayId
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const diyUser = this.users.find((user) => user._id === session._diy);
      const isDIYFirstname = diyUser.firstname
        ? diyUser.firstname.toLowerCase().includes(searchQuery.toLowerCase())
        : false;
      const isDIYLastname = diyUser.lastname
        ? diyUser.lastname.toLowerCase().includes(searchQuery.toLowerCase())
        : false;
      const craftsmanUser = this.users.find(
        (user) => user._id === session._craftsman
      );
      const isCraftsmanFirstname = craftsmanUser.firstname
        ? craftsmanUser.firstname
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        : false;
      const isCraftsmanLastname = craftsmanUser.lastname
        ? craftsmanUser.lastname
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        : false;

      return (
        isDisplayID ||
        isDIYFirstname ||
        isDIYLastname ||
        isCraftsmanFirstname ||
        isCraftsmanLastname
      );
    });
    this.setDataSource(filteredSessions);
  }

  // ---------------------------------------------------------------------------------------------
  // @ Public methods
  // ---------------------------------------------------------------------------------------------

  /**
   * Formats the duration based on the provided parameters.
   * @param exactDuration The exact duration with milliseconds
   * @returns String representation of the duration in Minutes and Seconds.
   */
  public formatDuration(exactDuration: number) {
    const duration = moment.duration(exactDuration, 's');
    let hours =
      duration.hours() >= 10 ? duration.hours() : '0' + duration.hours();
    let minutes =
      duration.minutes() >= 10 ? duration.minutes() : '0' + duration.minutes();
    let seconds =
      duration.seconds() >= 10 ? duration.seconds() : '0' + duration.seconds();

    if (hours === '00') {
      return `${minutes}:${seconds}`;
    }
    return `${hours}:${minutes}:${seconds}`;
  }

  /**
   * Formats the total cost based on the provided parameters.
   * @param totalCostCent The total cost in cents
   * @returns A string represantion of the total costs
   */
  formatDIYCost(totalCostCent: number) {
    const totalCost = totalCostCent / 100;
    return `${totalCost.toFixed(2)}€`.replace('.', ',');
  }

  /**
   * Calculates and formats the craftsman's income based on the provided parameters.
   * @param totalCost The total cost in cents
   * @param provision The vSupport provision
   * @returns A string representation of the craftsman income
   */
  calculateCraftsmanIncome(totalCost: number, provision: number) {
    const totalIncome = (totalCost * (1 - provision)) / 100;
    return `${totalIncome.toFixed(2)}€`.replace('.', ',');
  }

  /**
   * Formats the user's name based on the provided parameters.
   * @param userId The user's id (_craftman or _diy)
   * @returns A string representatin of the user's first and lastname (Firstname Lastname)
   */
  formatName(userId: string) {
    if (!userId) return '';
    const user = this.users.find((user) => user._id === userId);
    if (!user) return '';
    if (user.firstname && user.lastname) {
      return user.firstname + ' ' + user.lastname;
    }
    return user._id;
  }
}
