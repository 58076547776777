<div class="content-wrap">
  <h1>Liste aller Fragen</h1>
  <div class="question-list__add-button">
    <button mat-mini-fab color="primary" [routerLink]="['/question-create']">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <!-- Search -->
  <mat-form-field>
    <mat-label>Gewerk</mat-label>
    <mat-select
      placeholder="Gewerk"
      [(value)]="selectedCraft"
      (selectionChange)="onCraftChange()"
    >
      <mat-option value="none"> - Nichts ausgewählt - </mat-option>
      <mat-option *ngFor="let craft of crafts" [value]="craft.slug">
        {{ craft.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Question List -->
  <mat-grid-list cols="12">
    <ng-container
      *ngFor="let question of filteredQuestions"
      class="question-list__container"
    >
      <mat-grid-tile colspan="6">
        <div class="question-list__question">
          {{ formatQuestion(question.question) }}
        </div>
      </mat-grid-tile>
      <mat-grid-tile colspan="3">
        {{ question.craft }}
      </mat-grid-tile>
      <mat-grid-tile colspan="3">
        <div class="question-list__actions">
          <button
            mat-button
            color="primary"
            [routerLink]="['/question-edit', question._id]"
          >
            Bearbeiten
          </button>
          <button mat-button color="warn" (click)="delete(question._id)">
            Löschen
          </button>
        </div>
      </mat-grid-tile>
    </ng-container>
  </mat-grid-list>
</div>
