import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Question, Craft } from 'src/app/models/question.model';
import { QuestionService } from 'src/app/providers/question.service';

@Injectable({ providedIn: 'root' })
export class CraftsResolver implements Resolve<Craft[]> {
  constructor(private questionService: QuestionService) {}

  /**
   * Resolver
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Craft[] | Observable<Craft[]> | Promise<Craft[]> {
    return this.questionService.getCrafts();
  }
}

@Injectable({ providedIn: 'root' })
export class QuestionsResolver implements Resolve<Question[]> {
  constructor(private questionService: QuestionService) {}

  /**
   * Resolver
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Question[] | Observable<Question[]> | Promise<Question[]> {
    return this.questionService.getQuestions();
  }
}

@Injectable({ providedIn: 'root' })
export class QuestionResolver implements Resolve<Question> {
  constructor(private questionService: QuestionService) {}

  /**
   * Resolver
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Question | Observable<Question> | Promise<Question> {
    const id = route.paramMap.get('id');
    return this.questionService.getQuestion(id);
  }
}
