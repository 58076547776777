import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './user/login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { QuestionCreateComponent } from './question/question-create/question-create.component';
import { QuestionEditComponent } from './question/question-edit/question-edit.component';
import { QuestionListComponent } from './question/question-list/question-list.component';
import { CraftsResolver, QuestionsResolver, QuestionResolver } from './providers/question.resolvers';
import { QuestionGuard } from './guards/question.guard';
import { UserListComponent } from './user/list/list.component';
import { AuthGuard } from './auth/auth.guard';
import { OwnUserResolver, UserResolver, UsersResolver, } from './providers/user.resolver';
import { UserEditComponent } from './user/edit/edit.component';
import { UserCreateComponent } from './user/create/create.component';
import { FieldTagsResolver } from './providers/fieldTag.resolver';
import { FieldTagsComponent } from './field-tags/field-tags.component';
import { SettlementsComponent } from './settlements/settlements.component';
import { SessionListComponent } from './session/session-list/session-list.component';
import { SessionResolver, SessionsResolver } from './providers/session.resolvers';
import { SessionEditComponent } from './session/session-edit/session-edit.component';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent },
  {
    path: 'field-tags',
    resolve: {
      fieldTags: FieldTagsResolver,
      user: OwnUserResolver,
    },
    component: FieldTagsComponent,
  },
  {
    path: 'question-create',
    resolve: { crafts: CraftsResolver },
    component: QuestionCreateComponent,
  },
  {
    path: 'question-edit/:id',
    canActivate: [QuestionGuard],
    resolve: {
      crafts: CraftsResolver,
      question: QuestionResolver,
    },
    component: QuestionEditComponent,
  },
  {
    path: 'question-list',
    resolve: {
      crafts: CraftsResolver,
      questions: QuestionsResolver,
    },
    component: QuestionListComponent,
  },
  {
    path: 'settlements',
    component: SettlementsComponent
  },
  {
    path: 'sessions',
    children: [
      {
        path: '',
        resolve: {
          users: UsersResolver,
          sessions: SessionsResolver,
        },
        component: SessionListComponent,
      },
      {
        path: ':id',
        resolve: {
          session: SessionResolver,
        },
        component: SessionEditComponent,
      },
    ]
  },
  {
    path: 'users',
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        resolve: {
          crafts: CraftsResolver,
          users: UsersResolver,
        },
        component: UserListComponent,
      },
      {
        path: 'create',
        canActivate: [AuthGuard],
        resolve: {
          fieldTags: FieldTagsResolver,
        },
        component: UserCreateComponent,
      },
      {
        path: 'edit/:id',
        canActivate: [AuthGuard],
        resolve: {
          crafts: CraftsResolver,
          fieldTags: FieldTagsResolver,
          user: UserResolver,
        },
        component: UserEditComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
