import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginModule } from './user/login/login.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { AuthGuard } from './auth/auth.guard';
import { AuthInterceptor } from './auth/auth-interceptor';
import { ErrorInterceptor } from './error/error-interceptor';
import { QuestionListModule } from './question/question-list/question-list.module';
import { QuestionCreateModule } from './question/question-create/question-create.module';
import { QuestionEditModule } from './question/question-edit/question-edit.module';
import { NavigationModule } from './navigation/navigation.module';
import { UserListModule } from './user/list/list.module';
import { UserEditModule } from './user/edit/edit.module';
import { UserCreateModule } from './user/create/create.module';
import { FieldTagsModule } from './field-tags/field-tags.module';
import { SessionListModule } from './session/session-list/session-list.module';
import { SessionEditModule } from './session/session-edit/session-edit.module';
import { SettlementsModule } from './settlements/settlements.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LoginModule,
    FieldTagsModule,
    DashboardModule,
    SessionListModule,
    SessionEditModule,
    QuestionListModule,
    QuestionCreateModule,
    QuestionEditModule,
    SettlementsModule,
    UserListModule,
    UserCreateModule,
    UserEditModule,
    NavigationModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AuthGuard,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
