import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Answer, Craft } from 'src/app/models/question.model';
import { QuestionService } from 'src/app/providers/question.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/providers/notification.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-question-create',
  templateUrl: './question-create.component.html',
  styleUrls: ['./question-create.component.scss'],
})
export class QuestionCreateComponent implements OnInit, OnDestroy {
  crafts: Craft[];
  questionForm: FormGroup;
  hasCorrectAnswer: boolean = true;
  private unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private questionService: QuestionService,
    private notificationService: NotificationService,
    private formBuilder: FormBuilder
  ) {}

  // ---------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // ---------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.crafts = this.route.snapshot.data['crafts'];
    this.questionForm = this.formBuilder.group({
      craft: ['', [Validators.required]],
      question: ['', [Validators.required]],
      answers: this.formBuilder.array([]),
    });

    this.addAnswer(); // add first answer option
  }

  /**
   * On delete
   */
  ngOnDestroy(): void {
    this.unsubscribeAll.complete();
  }

  // ---------------------------------------------------------------------------------------------
  // @ Getters and Setters
  // ---------------------------------------------------------------------------------------------

  /**
   * Gets the answers Formarrays for dynamic rendering
   */
  get answers(): FormArray {
    return this.questionForm.get('answers') as FormArray;
  }

  // ---------------------------------------------------------------------------------------------
  // @ Private methods
  // ---------------------------------------------------------------------------------------------

  /**
   * Checks if at least one answer is marked as correct in the answers
   * @returns result on existing correct answer as boolean
   */
  private checkForCorrectAnswer(): boolean {
    const answers = this.questionForm.get('answers').value;

    // check that at least one answer is marked as correct
    let hasCorrect = false;
    answers.forEach((answerForm: Answer) => {
      if (answerForm.rightAnswer == true) hasCorrect = true;
    });
    this.hasCorrectAnswer = hasCorrect;
    return hasCorrect;
  }

  // ---------------------------------------------------------------------------------------------
  // @ Public methods
  // ---------------------------------------------------------------------------------------------

  /**
   * add an answer form to the form
   */
  addAnswer() {
    const answerForm = this.formBuilder.group({
      answer: ['', [Validators.required]],
      rightAnswer: [false, [Validators.required]],
    });
    answerForm.controls.rightAnswer.valueChanges
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (val: boolean) => {
          if (val) this.hasCorrectAnswer = true;
        },
      });
    this.answers.push(answerForm);
  }

  /**
   * Removes the answer form at a given index
   * @param index index that should be removed
   */
  removeAnswer(index: number) {
    this.answers.removeAt(index);
  }

  /**
   * Submits the question
   *
   * Fails if the form is invalid or if none of the entered answers is marked as
   * correct. Redirects to the question-list if successful.
   */
  onSubmit() {
    if (this.questionForm.invalid) {
      this.questionForm.markAllAsTouched();
      this.hasCorrectAnswer = false;
      return;
    }
    if (!this.checkForCorrectAnswer()) {
      return;
    }
    const formValues = this.questionForm.value;

    this.questionService.createQuestion(formValues).subscribe({
      next: () => {
        this.notificationService.setNotification = {
          type: 'success',
          message: 'Die Frage wurde erfolgreich hinzugefügt',
        };
        this.router.navigate(['/question-list']);
      },
      error: () => {
        this.notificationService.setNotification = {
          type: 'error',
          message: 'Es ist ein Fehler aufgetreten',
        };
      },
    });
  }
}
