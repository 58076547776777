import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';

import { environment } from '../../environments/environment';
import { Settlement } from '../models/payment.model';
import { PaymentService } from '../providers/payment.service';

@Component({
  selector: 'app-settlements',
  templateUrl: './settlements.component.html',
  styleUrls: ['./settlements.component.scss'],
})
export class SettlementsComponent implements OnInit {
  restServerUrl: string;
  selectedYear: string;
  possibleYears: string[] = [];
  selectedMonth: string;
  settlements: MatTableDataSource<Settlement>;
  displayedColumns: string[];

  private unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(private paymentService: PaymentService) {
    this.restServerUrl = environment.apiServer;
  }

  // ---------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // ---------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    this.selectedYear = currentYear.toString();
    this.selectedMonth = ('0' + currentDate.getMonth()).slice(-2);
    const OPERATOR_RELEASE_YEAR = 2023;
    for (let i = currentYear; i >= OPERATOR_RELEASE_YEAR; i--) {
      this.possibleYears.push(i.toString());
    }

    // get settlements
    this.displayedColumns = ['amount', 'firstname', 'lastname', 'actions'];
    this.getSettlements();
  }

  /**
   * On delete
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.unsubscribeAll.next(true);
    this.unsubscribeAll.complete();
  }

  // ---------------------------------------------------------------------------------------------
  // @ Public methods
  // ---------------------------------------------------------------------------------------------

  /**
   * Gets the settlements for the selected month and year.
   */
  public getSettlements() {
    this.paymentService
      .getSettlements(this.selectedYear, this.selectedMonth)
      .subscribe({
        next: (settlements) => {
          this.settlements = new MatTableDataSource(settlements);
        },
      });
  }
}
