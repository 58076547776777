import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from '../app-routing.module';
import { MaterialModule } from '../material.module';
import { NavigationComponent } from './navigation.component';


@NgModule({
  declarations: [
    NavigationComponent
  ],
  entryComponents: [
    NavigationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
  ],
  bootstrap: [
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [NavigationComponent]
})
export class NavigationModule {}
