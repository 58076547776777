<div class="content-wrap">
  <ng-container>
    <h2>Frage bearbeiten</h2>
    <form [formGroup]="questionForm" #formDirective="ngForm">
      <!-- Craft input -->
      <mat-form-field>
        <mat-label>Gewerk</mat-label>
        <mat-select formControlName="craft" placeholder="Gewerk" multiple>
          <mat-option *ngFor="let craft of crafts" [value]="craft.slug">
            {{ craft.name }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            (questionForm.get('craft').dirty ||
              questionForm.get('craft').touched) &&
            questionForm.get('craft').errors
          "
        >
          Gewerk notwendig
        </mat-error>
      </mat-form-field>

      <!-- Question input -->
      <mat-form-field>
        <textarea
          matInput
          formControlName="question"
          placeholder="Frage"
        ></textarea>
        <mat-error
          *ngIf="
            (questionForm.get('question').dirty ||
              questionForm.get('question').touched) &&
            questionForm.get('question').errors
          "
        >
          Frage notwendig
        </mat-error>
      </mat-form-field>

      <!-- Answer inputs -->
      <ng-container formArrayName="answers">
        <ng-container
          *ngFor="let answerForm of answers.controls; let i = index"
        >
          <div class="answer-group" [formGroupName]="i">
            <mat-form-field class="answer_input">
              <input
                matInput
                formControlName="answer"
                type="text"
                placeholder="Antwort"
              />
              <mat-error
                *ngIf="
                  (answerForm.get('answer').dirty ||
                    answerForm.get('answer').touched) &&
                  answerForm.get('answer').errors
                "
              >
                Antwort notwendig
              </mat-error>
            </mat-form-field>

            <!-- Correct Answer dropdown -->
            <mat-form-field class="answer_response">
              <mat-select formControlName="rightAnswer">
                <mat-option [value]="false">Falsch</mat-option>
                <mat-option [value]="true">Richtig</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-icon class="answer_icon" (click)="removeAnswer(i)"
              >delete</mat-icon
            >
          </div>
        </ng-container>
        <p *ngIf="!hasCorrectAnswer" class="errors">
          Es muss mindestens eine Antwort als "Richtig" markiert sein.
        </p>
      </ng-container>

      <div class="button-wrap">
        <button mat-mini-fab color="primary" (click)="addAnswer()">
          <mat-icon>add</mat-icon>
        </button>

        <button mat-flat-button color="primary" (click)="onSubmit()">
          Speichern
        </button>
      </div>
    </form>
  </ng-container>
</div>
